import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx200DayMaChartTemplate from '@/templates/crypto/xxx-200-day-moving-average-chart';

export default function Solana200DayMaChart({ location }: GatsbyPageProps) {
  return (
    <Xxx200DayMaChartTemplate
      location={location}
      articleId={ArticleList.SOLANA_200_DAY_MOVING_AVERAGE_CHART}
      token={Token.SOLUSDT}
      coinFullName="Solana"
      coinAbbreviation="SOL"
    ></Xxx200DayMaChartTemplate>
  );
}
